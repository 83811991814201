<template>
  <main class="main-content-wrapper">
    <router-view />
  </main>
</template>

<script>
export default {
  name: 'main-content'
}
</script>

<style lang="scss">
.main-content-wrapper {
  height: 100%;
}
</style>
