<template>
  <div
    class="container"
    :class="{'menu-expanded':expanded}"
    v-loading.fullscreen.lock="loading"
    element-loading-text="拼命加载中">
    <template v-if="!loading">
      <div class="left-area">
        <div class="logo-row">
          <div class="expand-icon" @click="$store.commit('common/toggleExpanded')">
            <img src="../assets/img/menus.png" :title="expanded ? '收起菜单' : '展开菜单'">
          </div>
          <div class="logo-area" v-show="expanded">
            <img src="../assets/img/logo_long.png">
          </div>
        </div>
        <menu-nav></menu-nav>
        <div class="logout-row">
          <!-- <div class="logout-icon" @click="logout">
            <img src="../assets/img/logout.png" title="退出登录">
          </div>
          <div class="empty" v-show="expanded"></div> -->
          <el-popover
            placement="right"
            width="100"
            trigger="hover"
            popper-class="user-circle-popover">
            <div>
              <div class="nickname">{{ nickname }}</div>
              <div class="btn" @click="dialogVisible = true">修改密码</div>
              <div class="btn" @click="logout">退出登录</div>
            </div>
            <div class="user-circle" slot="reference">{{ nickname.substring(0,1) }}</div>
          </el-popover>
        </div>
      </div>
      <div class="right-area scrollbar">
        <main-content />
      </div>
    </template>

    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="400px">
      <el-form :model="editPasswordForm" :rules="editPasswordFormRules" ref="ruleForm" autocomplete="off" disableautocomplete label-width="80px" size="small">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input type="password" v-model.trim="editPasswordForm.oldPassword" autocomplete="off" disableautocomplete></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model.trim="editPasswordForm.password" autocomplete="off" disableautocomplete></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input type="password" v-model.trim="editPasswordForm.confirmPassword" autocomplete="off" disableautocomplete></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="editPasswordSubmit" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MenuNav from '@/components/menu-nav'
import MainContent from '@/components/main-content'
import {
  clearLoginInfo
} from '@/utils'
export default {
  data () {
    var confirmPasswordVali = (rule, value, callback) => {
      if (value !== this.editPasswordForm.password) {
        callback(new Error('密码不一致'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      nickname: 'Admin',
      dialogVisible: false,
      editPasswordForm: {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      editPasswordFormRules: {
        oldPassword: [
          { required: true, min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, min: 6, max: 20, trigger: 'blur', validator: confirmPasswordVali }
        ]
      }
    }
  },
  components: {
    MenuNav,
    MainContent
  },

  computed: {
    expanded () {
      return this.$store.state.common.expanded
    }
  },

  methods: {
    // 获取当前管理员信息
    // getUserInfo () {
    //   this.$http({
    //     url: this.$http.adornUrl('/sys/sysuser/info'),
    //     method: 'get',
    //     params: this.$http.adornParams()
    //   }).then(({ data }) => {
    //     if (data && data.code === 0) {
    //       this.loading = false
    //       this.$store.commit('user/updateId', data.user.userId)
    //       this.$store.commit('user/updateName', data.user.username)
    //     }
    //   })
    // },

    getUserName () {
      this.$http({
        url: this.$http.adornUrl('/user/userName'),
        method: 'post',
        params: this.$http.adornParams()
      }).then(data => {
        this.nickname = _.get(data, 'datas.nickName', 'Admin')
      })
    },

    // 退出登录
    logout () {
      clearLoginInfo()
      // this.$router.push({
      //   name: 'login'
      // })
      window.location.href = '/login'
    },

    editPasswordSubmit () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl('/user/updatepassword'),
            method: 'post',
            data: this.$http.adornData(this.editPasswordForm)
          }).then(data => {
            if (data.code === 200) {
              this.logout()
            } else {
              this.$message.warning(data.msg || '密码修改不成功')
            }
          })
        } else {
          return false
        }
      })
    }
  },

  created () {
    // this.getUserInfo()
    this.getUserName()
  }
}

</script>

<style lang="scss">
  .container {
    position: absolute;
    background: #f9f9f9;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;

    &.menu-expanded {
      .left-area {
        width: 320px;
      }
    }

    .right-area {
      flex: 1;
    }

    .left-area {
      width: 60px;
      transition: width 0.2s;
      position: relative;
      display: flex;
      flex-direction: column;

      .logo-row,
      .logout-row {
        display: flex;
        align-items: center;
        background: #fff;
      }

      .expand-icon,
      .logout-icon {
        background: #242424;
        width: 60px;
        padding: 31px 19px 30px;
        cursor: pointer;
      }

      .logo-area {
        flex: 1;
        padding: 25px 20px;
        border-bottom: 1px solid #f3f3f3;
      }

      .logout-row {
        width: 60px;
        height: 88px;
        background: #242424;
        justify-content: center;
        .user-circle {
          color: #fff;
          margin: auto;
          background-color: grey;
          border-radius: 50%;
          font-size: 28px;
          line-height: 46px;
          width: 46px;
          text-align: center;
        }
        .empty {
          flex: 1;
        }
      }

      .menu-wrapper {
        height: calc(100% - 172px);
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        width: 1px;
        height: 100vh;
        content: "";
        background: rgba(103, 103, 103, 0.1);
      }
    }
  }
  body .user-circle-popover {
    // width: 100px;
    text-align: center;
    min-width: 0;
    line-height: 36px;
    padding: 0;
    >div {
      >div {
        padding: 0 10px;
        position: relative;
        z-index: 10;
      }
    }
    .nickname {
      // font-weight: bold;
      font-size: 13px;
      color: #fff;
      text-align: center;
      background-color: #bababa;
    }
    .btn {
      cursor: pointer;
      &:hover {
        background-color: #dbdbdb;
      }
    }
  }
</style>
