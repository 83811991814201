<template>
  <div class="menu-wrapper" :class="{expanded, scrollbar: !animating }">
    <div class="menu-bg-el">
      <div class="dark"></div>
      <div class="white"></div>
    </div>
    <div class="menu-container">
      <div
        class="menu-row"
        :class="{active: currentMenuId === m.id || currentMenuParentId === m.id}"
        v-for="m in visibleMenuList"
        :key="m.id"
      >
        <div class="icon-area" :class="{'red-dot': m.id == 3 && showRedDot}">
          <div class="icon-wrapper" v-if="expanded" @click="handleClickMenu(m)">
            <img :src="m.image">
          </div>
          <template v-else>
            <el-popover
              v-if="m.children"
              :visible-arrow="false"
              placement="left-start"
              popper-class="menu-popper"
              trigger="hover"
            >
              <transition name="fade">
                <div class="sub-menus-in-body">
                  <div
                    class="sub-row"
                    :class="{active: currentMenuId === s.id}"
                    v-for="s in m.children"
                    :key="s.id"
                    @click="pushRoute(s, m)"
                  >{{s.name}}</div>
                </div>
              </transition>
              <div slot="reference" class="icon-wrapper" @click="handleClickMenu(m)">
                <img :src="m.image">
              </div>
            </el-popover>
            <el-tooltip v-else :content="m.name" placement="left">
              <div class="icon-wrapper" @click="handleClickMenu(m)">
                <img :src="m.image">
              </div>
            </el-tooltip>
          </template>
        </div>
        <div class="text-area" v-show="expanded">
          <div class="menu-name-row" @click="handleClickMenu(m)">
            <span>{{m.name}}</span>
            <img v-if="m.children" :src="`/img/${m.expanded ? 'up' : 'down'}-arrow.png`">
          </div>
          <transition name="fade">
            <div class="sub-menus" v-show="m.expanded" v-if="m.children">
              <div
                class="sub-row"
                :class="{active: currentMenuId === s.id}"
                v-for="s in m.children"
                :key="s.id"
                @click="pushRoute(s, m)"
              >{{s.name}}</div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { menuList } from '../utils/constants.js'
import { flattenArray } from '../utils'

function processMenu (menus, routes) {
  const children = []
  for (const m of menus) {
    if (m.children) {
      const visibleChildren = processMenu(m.children, routes)
      if (visibleChildren.length) {
        m.children = visibleChildren
        children.push(m)
      }
    } else {
      const route = routes.find(d => d.name === m.route)
      if (route && route.meta.enabled) {
        children.push(m)
      }
    }
  }
  return children
}

export default {
  name: 'menu-nav',

  data () {
    return {
      showRedDot: false, // 侧边菜单栏生产管理图标是否显示红点（生产监控页面是否有异常状态）
      menuList: JSON.parse(JSON.stringify(menuList)),
      flattenedMenus: [],
      currentMenuId: '',
      currentMenuParentId: '',
      animating: false,
      dynamicRoutes: []
    }
  },

  computed: {
    expanded: {
      get () { return this.$store.state.common.expanded }
    },
    visibleMenuList () {
      // console.log(JSON.parse(JSON.stringify(this.menuList)))
      // console.log(JSON.parse(JSON.stringify(this.dynamicRoutes)))
      return processMenu(this.menuList, this.dynamicRoutes)
    }
  },

  watch: {
    $route: 'handleRouteChange',

    expanded () {
      this.animating = true
      setTimeout(() => {
        this.animating = false
      }, 300)
    }
  },

  methods: {
    navigateToHome () {
      if (this.$route.name !== 'home') {
        this.$router.push({ name: 'home' })
      }
    },

    handleClickMenu (m) {
      if (m.children) {
        if (!this.expanded) return
        m.expanded = !m.expanded
      } else {
        this.pushRoute(m)
        this.currentMenuParentId = ''
      }
    },

    pushRoute (menu, parent) {
      this.currentMenuId = menu.id
      if (parent) {
        this.currentMenuParentId = parent.id
      }
      if (menu.route && this.$route.name !== menu.route) {
        this.$router.push({ name: menu.route })
      }
    },

    // 路由操作
    handleRouteChange (r) {
      const menu = this.flattenedMenus.find(m => m.route === r.name)
      if (menu) {
        this.currentMenuId = menu.id
        this.currentMenuParentId = menu.parentId
      } else {
        this.currentMenuId = ''
      }
    },

    /** 判断生产管理图标是否需要添加红点提示 */
    havenew () {
      this.$http({
        url: this.$http.adornUrl('/production/carPermission/havenew'),
        method: 'post'
      }).then((data) => {
        this.showRedDot = !!data.datas
      })
    }
  },

  created () {
    this.dynamicRoutes = JSON.parse(sessionStorage.getItem('dynamicRoutes')) || []
    this.flattenedMenus = flattenArray(menuList)
    this.handleRouteChange(this.$route)

    this.havenew()
    this.havenewInterval = setInterval(() => {
      this.havenew()
    }, 60 * 1000)
  },

  beforeDestroy () {
    clearInterval(this.havenewInterval)
  }
}
</script>

<style lang="scss">
.menu-wrapper {
  overflow-x: hidden;
  position: relative;
  &::-webkit-scrollbar-track {
    background: #242424 !important;
    border-radius: 0 !important;
  }
  &.expanded::-webkit-scrollbar-track {
    background: #fff !important;
    border-radius: 0 !important;
  }
  .menu-bg-el {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    .dark {
      width: 60px;
      height: 100%;
      background: #242424;
    }
  }
  .menu-container {
    position: relative;
    z-index: 2;
  }
  .menu-row {
    display: flex;
    min-height: 60px;
    background: #fff;
    .icon-area {
      background: #242424;
      width: 60px;
      display: flex;
      align-items: flex-start;
      transition: all 0.2s;
      .icon-wrapper {
        cursor: pointer;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
      }
      &.red-dot {
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 12px;
          right: 12px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: red;
        }
      }
    }
    .text-area {
      flex: 1;
    }
    .menu-name-row {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      line-height: 60px;
      font-size: 16px;
      color: #1a1a1a;
      transition: all 0.2s;
      white-space: nowrap;
      overflow: hidden;
      &:hover {
        background: #f9f9f9;
      }
    }
    &.active {
      .icon-wrapper {
        background: #000000;
        position: relative;
        &::after {
          position: absolute;
          top: 2px;
          right: 0;
          content: "";
          background: #c20c0c;
          width: 3px;
          height: 58px;
        }
      }
      .menu-name-row {
        background: #f9f9f9;
      }
    }
    .sub-menus {
      .sub-row {
        cursor: pointer;
        padding: 15px 20px 15px 43px;
        font-size: 14px;
        color: #5e5e5e;
        transition: all 0.2s;
        &.active,
        &:hover {
          font-weight: 700;
          background: #f9f9f9;
          color: #181717;
        }
      }
    }
  }
}
.el-popper.menu-popper {
  border: none;
  background: #242424;
  padding: 0;
  .sub-menus-in-body {
    color: #fff;
    .sub-row {
      padding: 10px;
      line-height: 24px;
      cursor: pointer;
      transition: all 0.2s;
      &.active,
      &:hover {
        background: #414040;
      }
    }
  }
}
</style>
